<template>
<form class="email-form" :name="`form_${subscriberId}`" v-on:submit.prevent="onSubmit">
    <div class="email-input">
        <i class="email-icon fa-regular fa-envelope" />
        <input
            :id="`emailInput_${subscriberId}`"
            v-model="email"
            :placeholder="$t('FORM.EMAIL_ADDRESS')"
            class="input"
            type="email"
            inputmode="email"
            aria-label="Email"
        >
    </div>
    <input
        v-for="dataField in dataFields"
        :id="dataField.id"
        :key="dataField.id"
        :name="dataField.name"
        :value="dataField.value"
        type="hidden"
    >
    <ButtonElement
        :id="`submitButton_${subscriberId}`"
        :class="`${buttonStyle} submit-btn`"
        v-on:click="submitEmail"
        v-on:keydown.enter="submitEmail"
    >
        {{ buttonText || $t('FORM.REMIND_ME') }}
    </ButtonElement>
</form>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ButtonElement from '$components/ButtonElement.vue'

interface DataField {
    name: string
    id: string
    value: string
}

export default defineComponent({
    components: {
        ButtonElement
    },

    props: {
        subscriberId: {
            type: String,
            required: false,
            default: 'jackboxGamesNewsletter'
        },

        dataFields: {
            type: Array as Prop<DataField[]>,
            require: false,
            default: () => []
        },

        buttonText: {
            type: String as Prop<string | null>,
            required: false
        },

        buttonStyle: {
            type: String,
            required: false,
            default: 'primary'
        }
    },

    data() {
        return {
            email: ''
        }
    },

    computed: {
        localizedSubscriberId(): string {
            /**
             * Treat TeaserPage special as it is using hidden input instead
             */
            if (this.subscriberId === 'TeaserPage') {
                return this.subscriberId
            }

            switch (this.$i18n.locale) {
            case 'fr':
                return 'frenchNewsletter'
            case 'de':
                return 'germanNewsletter'
            case 'it':
                return 'italianNewsletter'
            case 'es':
                return 'spanishESNewsletter'
            case 'es-XL':
                return 'spanishLANewsletter'
            default:
                return this.subscriberId
            }
        }
    },
    mounted() {
        void this.setupListrak()
    },
    methods: {
        onSubmit() {
            return false
        },
        setupListrak() {
            // Gotta wait for the Listrak Async stuff to be ready
            // this ugly code is copy/ pasta from them
            // eslint-disable-next-line no-undef, camelcase, eqeqeq, func-names
            (function(d) { if (typeof _ltk === 'undefined') { if (document.addEventListener)document.addEventListener('ltkAsyncListener', () => { _ltk_util.ready(d) }); else { e = document.documentElement; e.ltkAsyncProperty = 0; e.attachEvent('onpropertychange', (e) => { if (e.propertyName == 'ltkAsyncProperty') { ltk_util.ready(d) } }) } } else { _ltk_util.ready(d) } }(() => {
                /** ******** Begin Custom Code ********* */
                // eslint-disable-next-line no-undef
                _ltk.Signup.New(this.localizedSubscriberId, `emailInput_${this.subscriberId}`, _ltk.Signup.TYPE.CLICK, `submitButton_${this.subscriberId}`, 'email')

                // set values for the hidden data fields for listrak
                this.dataFields.forEach((dataField) => {
                // eslint-disable-next-line no-undef
                    _ltk.Signup.SetValue(this.localizedSubscriberId, dataField.name, dataField.value)
                })
                /** ******** End Custom Code *********** */
            }))
        },
        submitEmail() {
            if (this.email.length === 0) {
                this.$toast.add({
                    id: 'subscribe1',
                    type: 'info',
                    text: this.$t('TOAST.EMPTY_EMAIL'),
                    duration: 2500
                })
                return
            }
            if (!this.email.match(/.+@.+\..+/)) {
                this.$toast.add({
                    id: 'subscribe2',
                    type: 'info',
                    text: this.$t('TOAST.INVALID_EMAIL'),
                    duration: 2500
                })
                return
            }
            // show our confrimation toast
            this.$toast.add({
                id: 'subscribe3',
                type: 'info',
                text: this.$t('TOAST.EMAIL_SIGN_UP'),
                duration: 2500
            })

            this.email = ''
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.input {
    color: var(--neutral-300);
    background-color: var(--surface-50);
    border: none;
    border-bottom: 2px solid var(--primary-300);
    border-radius: 4px 4px 0px 0px;
    margin: auto;
    height: 40px;
    width: 100%;
    z-index: 0;
    font-family: Inter;
    font-size: 14px;
    padding-left: 36px;

    &::-webkit-input-placeholder {
        color: var(--neutral-300);
    }

    &:focus-visible {
        outline: 1px solid var(--primary-300);
    }
}

.email-icon {
    padding-top: 12px;
    padding-left: 10px;
    position: absolute;
    z-index: 1;
    width: 16px;
}

.submit-btn {
    margin-top: 16px;
    width: 100%;
}
</style>
